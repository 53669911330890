import { media, color, Color } from "@sencrop/ui-components";
import { WebTitle, webtext } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import ImageElement from "../components/ImageElement";
import LinkElement from "../containers/LinkElement";
import { Link } from "../containers/LinkRenderer";
import { blockCss, containerCss, contentCss } from "../helpers/style";

const BACKGROUND_COLOR_MAPPING: Record<string, Color> = {
  grey: "background-primary",
  white: "background-primary:10",
};

const SectionIntro = ({
  backgroundColor,
  title,
  subhead,
  link,
  mainDescription,
  parts,
  verticalLineAfterSection,
  bigImages,
  noTextForParts,
}: GatsbyTypes.SectionIntroFragmentFragment) => {
  return (
    <Background backgroundColor={backgroundColor as "grey" | "white"}>
      <Wrapper
        $line={
          verticalLineAfterSection === null
            ? true
            : (verticalLineAfterSection as boolean)
        }
      >
        <Header>
          {subhead && (
            <SubHead>
              <WebTitle size="s" color="branding-secondary">
                {subhead}
              </WebTitle>
            </SubHead>
          )}
          <TitleContent>
            <WebTitle as="h2" sizes={["l", "xl", "xl", "xl"]} color="primary">
              {title}
            </WebTitle>
          </TitleContent>
        </Header>
        {mainDescription?.childMarkdownRemark?.html && (
          <TextContent
            dangerouslySetInnerHTML={{
              __html: mainDescription.childMarkdownRemark.html,
            }}
            style={{ whiteSpace: "pre-wrap", position: "relative" }}
          />
        )}
        {parts && (
          <PartsContainer
            $noText={noTextForParts as boolean}
            nbParts={parts.length}
          >
            {parts.map((part, index) => (
              <div key={index}>
                {part?.image && (
                  <div>
                    <ImageElement
                      gatsbyImageData={part.image.gatsbyImageData}
                      style={{
                        height: bigImages
                          ? "198px"
                          : noTextForParts
                          ? "100%"
                          : "120px",
                        ...(parts.length < 4 &&
                          !noTextForParts && { marginBottom: "1rem" }),
                        ...(parts.length < 4 &&
                          !noTextForParts && { marginTop: "1.5rem" }),
                      }}
                      alt={part.image.title}
                    />
                  </div>
                )}
                {!noTextForParts && parts.length < 4 && (
                  <>
                    {part?.title && (
                      <WebTitle
                        as="h3"
                        size="m"
                        color="primary"
                        style={{ marginBottom: "0.375rem" }}
                      >
                        {part.title}
                      </WebTitle>
                    )}
                    {part?.text?.childMarkdownRemark?.html && (
                      <TextContent
                        dangerouslySetInnerHTML={{
                          __html: part.text.childMarkdownRemark.html,
                        }}
                        style={{ whiteSpace: "pre-wrap" }}
                      />
                    )}
                    {part?.link && (
                      <LinkElement
                        link={part?.link as Link}
                        style={{ marginTop: "1rem" }}
                        centered
                      >
                        {part?.link.label}
                      </LinkElement>
                    )}
                  </>
                )}
              </div>
            ))}
          </PartsContainer>
        )}
        {link && (
          <LinkElement
            link={link as Link}
            style={{ marginTop: "1rem" }}
            centered
          >
            {link.label}
          </LinkElement>
        )}
      </Wrapper>
    </Background>
  );
};

export default SectionIntro;

export const fragment = graphql`
  fragment SectionIntroFragment on ContentfulSectionIntro {
    backgroundColor
    title
    subhead
    link {
      ...LinkFragment
    }
    mainDescription {
      id
      childMarkdownRemark {
        html
      }
    }
    verticalLineAfterSection
    bigImages
    noTextForParts
    parts {
      image {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        title
      }
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      link {
        ...LinkFragment
      }
    }
  }
`;

type BackgroundProps = {
  backgroundColor: "grey" | "white";
};

const Background = styled.div<BackgroundProps>`
  position: relative;
  ::before {
    z-index: -1;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    ${(props) =>
      props.backgroundColor &&
      css`
        background-color: ${color(
          BACKGROUND_COLOR_MAPPING[props.backgroundColor]
        )};
      `}
  }
`;

type WrapperProps = {
  $line: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  ${containerCss}
  ${blockCss}
  ${contentCss}
  height: auto;
  text-align: center;
  padding-bottom: 2.5rem;
  padding-top: 1.5rem;
  ${(props) =>
    props.$line &&
    css`
      :after {
        z-index: 2;
        content: "";
        position: absolute;
        background-color: ${color("branding-secondary")};
        width: 2px;
        height: 2.5rem;
        margin-top: 1.6rem;
      }
    `}
  ${media.greaterThan("tablet")`
    padding-top: 2.5rem;
    padding-bottom: 56px;
    :after {
      height: 4rem;
      margin-top: 2rem;
    }
  `}
`;

const Header = styled.div`
  max-width: 796px;
  margin: 0 auto;
`;

const SubHead = styled.div`
  p {
    color: ${color("branding-secondary")};
  }
  margin-bottom: 0.5rem;
`;

const TitleContent = styled.div`
  margin-bottom: 0.5rem;
  ${media.greaterThan("tablet")`
        margin-bottom: 1rem;
  `}
`;

const TextContent = styled.div`
  max-width: 796px;
  margin: auto;
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

type PartsContainerProps = {
  nbParts: number;
  $noText: boolean;
};

const PartsContainer = styled.div<PartsContainerProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  padding-top: 0.5rem;
  img {
    object-fit: contain !important;
  }
  ${(props) =>
    props.nbParts < 4 &&
    css`
      grid-gap: 1rem;
      grid-template-columns: 1fr;
    `}
  ${(props) =>
    props.nbParts >= 4 &&
    css`
      grid-gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
    `}
    ${(props) => css`
    ${media.greaterThan("tablet")`
        grid-template-columns: repeat(${props.nbParts}, 1fr);
        padding-top: 1rem;
        ${
          props.$noText &&
          props.nbParts < 4 &&
          css`
            grid-template-columns: repeat(
              ${props.nbParts % 2 === 0 ? "6" : "5"},
              1fr
            );
            > *:first-child {
              grid-column-start: ${props.nbParts < 3 ? "3" : "2"};
            }
          `
        };
      `};
  `};
`;
